import { useLazyQuery, useQuery } from "@apollo/client";
import { FETCH_CONTAINERS, FETCH_LOADING_PLAN } from "graphql/query";
import Spinner from "./Spinner";
import Errors from "./Errors";
import CharlesButton, { CharlesButtonWithArrow } from "./charles/base";
import { useEffect, useState } from "react";
import { Input } from "./Form";
import { MdCleaningServices } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { CiCalculator1 } from "react-icons/ci";

const getContainers = (planContainers, totalCbm) => {
  // loadingPlan like: {"20GP": 0, "40GP": 1, "40HQ": 2, "45HQ": 0}
  // need to convert it to [ {name: "40GP", cbm: 58, cost: 10000, emptySpace: 0}, {name: "40GP", cbm: 58, cost: 10000, emptySpace: 0}, ...]
  let remainingCbm = totalCbm;
  let totalCapacity = 0;

  const containers = planContainers.flatMap((container) => {
    const containerArray = Array(container.qty).fill();

    const containerObjects = containerArray.map(() => {
      totalCapacity += container.cbm;

      if (remainingCbm >= container.cbm) {
        remainingCbm -= container.cbm;
        return {
          name: container.name,
          cbm: container.cbm,
          cost: container.cost,
          emptySpace: 0,
        };
      } else {
        const emptySpace = container.cbm - remainingCbm;
        remainingCbm = 0;
        return {
          name: container.name,
          cbm: container.cbm,
          cost: container.cost,
          emptySpace,
        };
      }
    });

    return containerObjects;
  });

  // sort the containers by cbm from small to large
  containers.sort((a, b) => a.cbm - b.cbm);

  return { containers, remainingCapacity: totalCapacity - totalCbm };
};

const ContainerizationView = ({ totalCbm }) => {
  const roundedTotalCbm = totalCbm.toFixed(2);

  const { loading, error, data } = useQuery(FETCH_LOADING_PLAN, {
    variables: { totalCbm: roundedTotalCbm, specificContainers: null, rate: 1 },
  });
  const [showCalculator, setShowCalculator] = useState(false);

  if (loading) return <Spinner />;
  if (error) return <Errors errors={error} />;

  const { containers, remainingCapacity } = getContainers(
    data.loadingPlan,
    roundedTotalCbm,
  );

  return (
    <div>
      <div className="flex flex-wrap">
        {containers.map((c, index) => (
          <ContainerView container={c} key={index} />
        ))}
      </div>

      <LoadingPlanInfo
        planContainers={data.loadingPlan}
        totalCbm={roundedTotalCbm}
      />

      <div className="mt-2 opacity-70">
        <p>
          {remainingCapacity > 0 &&
            "You can add more products to fill up the container with empty space."}
        </p>
        <p>
          The default plan displayed above is the best plan with a container
          utilization rate of 100%.
        </p>
        <p>
          The algorithm is trying to find the lowest cost first, then the
          smallest total container capacity (the highest possible loading rate).
        </p>
      </div>

      <div className="mt-4 space-y-2">
        <CharlesButtonWithArrow
          onClick={() => setShowCalculator(!showCalculator)}
          show={showCalculator}
        >
          <div className="flex items-center">
            <CiCalculator1 className="text-xl" />
            Loading Plan Calculator
          </div>
        </CharlesButtonWithArrow>

        {showCalculator && (
          <Calculator className="mt-2" defaultTotalCbm={roundedTotalCbm} />
        )}
      </div>
    </div>
  );
};

const LoadingPlanInfo = ({ planContainers, totalCbm, rate }) => {
  const totalCost = planContainers.reduce(
    (acc, planContainer) => acc + planContainer.cost * planContainer.qty,
    0,
  );
  const totalCapacity = planContainers.reduce(
    (acc, planContainer) => acc + planContainer.cbm * planContainer.qty,
    // acc + planContainer.cbm * (rate / 100) * planContainer.qty,
    0,
  );

  return (
    <div className="p-4 border border-gray-200 w-fit rounded-md">
      <div className=" text-base font-bold mb-4">Loading Plan</div>

      <div className="text-sm flex">
        <div>
          {planContainers.map((container, index) => (
            <div key={index}>
              {container.qty > 0 && (
                <div>
                  <span className="font-bold">{container.name}</span>
                  <span className="ml-2">
                    (CBM: {container.cbm}, Cost: {container.cost} {"USD"})
                  </span>
                  <span className="mx-4">x</span>
                  <span className="font-bold">{container.qty}</span>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className=" border border-gray-200 mx-4 " />

        <div className="space-y-2">
          <div className="flex">
            <label className="w-28">Total CBM: </label>
            <span className="font-bold">{totalCbm} m³</span>
          </div>
          <div className="flex">
            <label className="w-28">Total Cost: </label>
            <span className="font-bold">
              {totalCost} {"USD"}
            </span>
          </div>
          <div className="flex">
            <label className="w-28">Empty Space: </label>
            <span className="font-bold">{totalCapacity - totalCbm} m³</span>
          </div>
          <div className="flex">
            <label className="w-28">Load Rate: </label>
            <span className="font-bold">
              {((totalCbm / totalCapacity) * 100).toFixed(2)} %
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContainerView = ({ container }) => {
  const loadingPercentage = (1 - container.emptySpace / container.cbm) * 100;
  const width = 2 * container.cbm; // width is baed on the cbm of the container, 1 cbm = 2px here, just for better visualization

  return (
    <div className="py-4">
      <div
        className="w-16 h-24 relative bg-gray-100 border-2  border-gray-500"
        style={{ width }}
      >
        <div
          className="w-full bg-green-500 absolute bottom-0 transition-all duration-500 ease-in-out"
          style={{
            height: `${loadingPercentage}%`,
          }}
        />
      </div>
      <div className="text-center mt-1">{container.name}</div>
    </div>
  );
};

const Calculator = ({ defaultTotalCbm }) => {
  const {
    loading: containerLoading,
    error: containerError,
    data: containerData,
  } = useQuery(FETCH_CONTAINERS);

  const [fetchLoadingPlan, { loading, error, data }] =
    useLazyQuery(FETCH_LOADING_PLAN);

  const [totalCBM, setTotalCBM] = useState(defaultTotalCbm);
  const [specificContainers, setSpecificContainers] = useState({});
  const [rate, setRate] = useState(100);

  const [finalLoadingPlan, setFinalLoadingPlan] = useState(null);

  useEffect(() => {
    if (data) {
      const result = data.loadingPlan.map((p) => p);

      // add the specific containers to the result
      Object.entries(specificContainers).forEach(([name, qty]) => {
        containerData.containers.forEach((c) => {
          if (c.name === name) {
            result.push({ ...c, qty });
          }
        });
      });

      setFinalLoadingPlan(result);
    }
  }, [data]);

  useEffect(() => {
    // clean plan when calculation conditions change
    setFinalLoadingPlan(null);
  }, [totalCBM, rate, specificContainers]);

  const onChangeQty = (name, value) => {
    setSpecificContainers((prev) => {
      if (value === "") {
        const { [name]: _, ...newSpecificContainers } = prev;
        return newSpecificContainers;
      }

      return { ...prev, [name]: parseInt(value) };
    });
  };

  const handleCalculate = () => {
    fetchLoadingPlan({
      variables: {
        totalCbm: totalCBM,
        specificContainers: JSON.stringify(specificContainers),
        rate: rate / 100,
      },
    });
  };

  if (containerLoading) return <Spinner />;
  if (containerError) return <Errors errors={containerError} />;

  return (
    <div className="flex items-center">
      <div className="text-sm p-4 w-fit rounded-md shadow-lg bg-gray-50 space-y-2">
        <div className="flex space-x-2 items-center">
          <label className="w-64">Total CBM (m³):</label>
          <Input
            type="number"
            className="w-24"
            min={0}
            value={totalCBM}
            onChange={(e) => setTotalCBM(parseFloat(e.target.value))}
          />
          <MdCleaningServices
            className="cursor-pointer text-sky-400"
            onClick={() => setTotalCBM(defaultTotalCbm)}
          />
        </div>

        <div className="flex space-x-2 items-center">
          <label className="w-64">Utilization Rate (%):</label>
          <Input
            className="w-24"
            type="number"
            min={0}
            max={100}
            value={rate}
            onChange={(e) => setRate(parseFloat(e.target.value))}
          />
          <MdCleaningServices
            className="cursor-pointer text-sky-400"
            onClick={() => setRate(100)}
          />
        </div>

        <div className="border border-gray-200 border-dashed" />

        <div className=" space-y-2">
          <label>Containers:</label>
          {containerData.containers.map((c, index) => (
            <div key={index} className="flex space-x-2 items-center ">
              <div className="w-64">
                <span className="font-bold">{c.name}</span>
                <span className="ml-2">
                  (CBM: {c.cbm}, Cost: {c.cost} {"USD"})
                </span>
              </div>

              <Input
                className="w-24"
                placeholder="unlimited"
                type="number"
                min={0}
                value={
                  specificContainers[c.name] !== undefined &&
                  specificContainers[c.name] !== null
                    ? specificContainers[c.name]
                    : ""
                }
                onChange={(e) => onChangeQty(c.name, e.target.value)}
              />

              <MdCleaningServices
                className="cursor-pointer text-sky-400"
                onClick={() => onChangeQty(c.name, "")}
              />
            </div>
          ))}
        </div>

        <CharlesButton
          onClick={handleCalculate}
          className="border border-sky-300 text-sky-500 hover:text-sky-800 hover:bg-sky-500 hover:border-sky-500 active:border-sky-700 text-xs px-2 py-1 rounded-md"
        >
          Calculate
        </CharlesButton>
      </div>

      {loading && <Spinner />}
      {error && <Errors errors={error} />}
      {finalLoadingPlan && (
        <>
          <FaArrowRightLong className="text-2xl mx-6" />

          <LoadingPlanInfo
            planContainers={finalLoadingPlan}
            totalCbm={totalCBM}
          />
        </>
      )}
    </div>
  );
};

export default ContainerizationView;
