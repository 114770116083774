import Page from "components/Page";
import SearchBar from "components/SearchBar";
import { useQuery } from "@apollo/client";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Link, useSearchParams } from "react-router-dom";
import { FETCH_RETAILER_ORDERS } from "./graphql";
import Status from "components/Status";
import moment from "moment";

const OrderListPage = () => {
  const { loading, error, data } = useQuery(FETCH_RETAILER_ORDERS);
  const [searchParams, setSearchParams] = useSearchParams({ q: "" });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const params = Object.fromEntries(searchParams.entries());
  console.log("params", params);

  const orders = data.retailerOrders.filter((i) => {
    const q = params.q.toLowerCase().replace(/[^a-z0-9]/g, "");
    if (q === "") return true;
    if (i.odooName.toLowerCase().includes(q)) return true;
    if (i.user.retailer?.name.toLowerCase().includes(q)) return true;
    return false;
  });

  return (
    <Page title="Retailer Orders">
      <div className="card m-6 p-6">
        <div className="flex items-center justify-between space-x-4">
          <div className="flex-1">
            <SearchBar
              autoFocus
              placeholder="Search by retailer name or order number."
              value={params.q}
              onChange={(q) => setSearchParams({ ...params, q })}
            />
          </div>
        </div>

        <div className="mt-4 whitespace-nowrap">
          <table>
            <thead>
              <tr>
                <th>Order</th>
                <th>Date</th>
                <th>Retailer</th>
                <th>Reminded</th>
                <th className="text-right">Total</th>
                <th className="text-right">Status</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr
                  key={order.id}
                  className="border-y border-gray-100 dark:border-gray-700"
                >
                  <td>
                    <Link to={order.id}>{order.odooName}</Link>
                  </td>
                  <td className="text-right">
                    {moment(order.createdAt).format("YYYY-MM-DD")}
                  </td>
                  <td className="w-full">
                    {order.user.retailer?.name} - {order.user.email}
                  </td>
                  <td className="text-right">
                    {order.remindPaymentAt
                      ? moment(order.remindPaymentAt).format("YYYY-MM-DD")
                      : "-"}
                  </td>
                  <td className="text-right">€ {order.total.toFixed(2)}</td>
                  <td>
                    <div className="flex justify-end">
                      <Status status={order.state} />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  );
};

export default OrderListPage;
