import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button } from "components/base";
import { Input, Switcher } from "components/Form";
import { Alert } from "components/Toast";
import { validateEmail } from "utils/string";
import { nanoid } from "nanoid";
import { parseError } from "apollo";
import CharlesButton from "components/charles/base";
import { useNavigate } from "react-router-dom";
import { RETAILER_CREATE_AGENT } from "../graphql";
import { isTesting } from "../utils";

function AgentForm({ hide }) {
  const [user, setUser] = useState({
    email: isTesting ? "test_" + nanoid().slice(-4) + "@waboba.com" : "",
    password: nanoid().slice(-16),
    firstName: isTesting ? "Test First Name" : "",
    lastName: isTesting ? "Test Last Name" : "",
  });
  const navigate = useNavigate();

  const [retailerCreateAgent, { loading }] = useMutation(
    RETAILER_CREATE_AGENT,
    {
      variables: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        password: user.password,
      },
      onCompleted: (res) => {
        Alert("success", "User saved.");
        navigate(`/retailer-store/agents/${res.retailerCreateAgent.user.id}`);
        if (hide) hide();
      },
      refetchQueries: ["FETCH_RETAILER_AGENTS"],
      onError: (error) => {
        Alert("error", parseError(error));
      },
    },
  );

  function saveHandler() {
    if (user.email === "" || !validateEmail(user.email)) {
      Alert("error", "Invalid email address.");
      return;
    }

    retailerCreateAgent();
  }

  return (
    <div className="space-y-6">
      <div>
        Input the agent email and name, and click save to create the agent.
      </div>

      <div className="p-4 lg:p-6 bg-white dark:bg-gray-900 rounded-xl">
        <div className="flex items-center">
          <label htmlFor="" className="w-1/4 inline-block">
            Email:
          </label>
          <Input
            type="email"
            validated={user.email === "" || validateEmail(user.email)}
            className="ml-4 flex-1"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
        </div>
        <div className="mt-4 flex items-center">
          <label htmlFor="" className="w-1/4 inline-block">
            First Name:
          </label>
          <Input
            className="ml-4 flex-1"
            value={user.firstName}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
          />
        </div>
        <div className="mt-4 flex items-center">
          <label htmlFor="" className="w-1/4 inline-block">
            Last Name:
          </label>
          <Input
            className="ml-4 flex-1"
            value={user.lastName}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
          />
        </div>
      </div>

      <div className="mx-1">
        <p>Once you create an agent, wis create an account for the agent.</p>
        <p>
          Wis auto automatically create a retailer which is belong to the agent
          with the same email address.
        </p>
        <p>So it is one account for the agent and the retailer.</p>
        <p>The retailer created will be belong to the agent.</p>
        <p>
          Agents has an `Agent Dashboard` page in retailer store. They can
          create more retailers themselves in the retailer store. They can see
          all the retailers and their orders belong to them.
        </p>
      </div>

      <div className="mt-6">
        <CharlesButton primary loading={loading} onClick={saveHandler}>
          Save
        </CharlesButton>
      </div>
    </div>
  );
}

export default AgentForm;
