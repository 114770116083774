import { useMutation } from "@apollo/client";
import CharlesButton from "components/charles/base";
import { Alert } from "components/Toast";
import { parseError } from "apollo";
import { RETAILER_STORE_START_SHIPPING } from "./graphql";

const StartShippingView = ({ orderId, hide }) => {
  const [startShipping, startShippingRes] = useMutation(
    RETAILER_STORE_START_SHIPPING,
    {
      variables: { orderId },
      onCompleted() {
        Alert("success", "The order has been marked as shipping successfully.");
        hide();
      },
      onError(err) {
        Alert("error", parseError(err));
      },
    },
  );

  return (
    <div>
      <div>Are you sure you want to mark this order as shipping?</div>

      <hr />

      <div>
        <CharlesButton
          primary
          onClick={startShipping}
          loading={startShippingRes.loading}
        >
          Yes, Mark as Shipping
        </CharlesButton>
      </div>
    </div>
  );
};

export default StartShippingView;
