import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import ProductSelector from "./ProductSelector";
import { Input } from "components/Form";
import { BsTrash } from "react-icons/bs";

const ProductMappingView = ({ product, setProduct }) => {
  const modal = useModals();

  console.log("ProductMappingView.product", product);

  function showProductSelector() {
    modal.present({
      title: "Select Product",
      children: <ProductSelector onSelect={onSelectProduct} />,
    });
  }

  function onSelectProduct(selectedProduct) {
    modal.hide();
    setProduct({
      ...product,
      bom: [
        ...product.bom,
        { wisProduct: selectedProduct, qty: 1, unitPrice: 0 },
      ],
    });
  }

  function onChangeBomItem(index, values) {
    setProduct({
      ...product,
      bom: product.bom.map((bom, i) => {
        if (i === index) {
          return { ...bom, ...values };
        }
        return bom;
      }),
    });
  }

  return (
    <div className="card px-6 py-4">
      <h4>Product Mapping</h4>
      <div className="opacity-70 text-xs">
        Map product to the item in our system -- WIS and Odoo. Set the correct
        qty and unit price for each item. This is important for the system to
        auto generate odoo orders and invoices.
      </div>
      <div className="mt-2 -mx-2">
        <table>
          <thead>
            <tr className=" whitespace-nowrap">
              <th>WIS Product</th>
              <th className="text-right">Total Price</th>
              <th className="text-right">QTY</th>
              <th className="text-right">Unit Price</th>
            </tr>
          </thead>
          <tbody>
            {product.bom.map((item, index) => (
              <tr
                key={index}
                className="border-y border-gray-100 dark:border-gray-700"
              >
                <td>
                  <div className="flex place-items-center space-x-2">
                    <CharlesButton
                      danger
                      onClick={() =>
                        setProduct({
                          ...product,
                          bom: product.bom.filter((_, i) => i !== index),
                        })
                      }
                    >
                      <BsTrash />
                    </CharlesButton>
                    <div>
                      <div className=" font-semibold">
                        {item.wisProduct.number}
                      </div>
                      <div>{item.wisProduct.name}</div>
                    </div>
                  </div>
                </td>
                <td className="text-right">
                  <Input
                    className="text-right w-32"
                    value={item.totalPrice}
                    onChange={(e) =>
                      onChangeBomItem(index, { totalPrice: e.target.value })
                    }
                  />
                </td>
                <td className="text-right">
                  <Input
                    className="text-right w-32"
                    value={item.qty}
                    onChange={(e) =>
                      onChangeBomItem(index, { qty: e.target.value })
                    }
                  />
                </td>

                <td className="text-right">
                  {(item.totalPrice / item.qty).toFixed(4)}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="font-semibold text-lg">
              <td>Total</td>

              <td className="text-right">
                {product.bom
                  .reduce((acc, i) => acc + parseFloat(i.totalPrice), 0)
                  .toFixed(2)}
              </td>
              <td className="text-right">
                {product.bom.reduce((acc, i) => acc + parseInt(i.qty), 0)}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
      <div className="mt-4">
        <CharlesButton onClick={showProductSelector}>
          + Add Product
        </CharlesButton>
      </div>
    </div>
  );
};

export default ProductMappingView;
