import { useQuery } from "@apollo/client";
import Page from "components/Page";
import { FETCH_RETAILER_STORE_PRODUCTS } from "./graphlq";
import Spinner from "components/Spinner";
import Errors from "components/Errors";
import { Link } from "react-router-dom";
import { IoIosCheckmarkCircle, IoIosRemoveCircle } from "react-icons/io";
import moment from "moment";
import { computeMaxPackage } from "./inventory";

const ProductList = () => {
  const { loading, error, data } = useQuery(FETCH_RETAILER_STORE_PRODUCTS);

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const rows = data.retailerStoreProducts.map((product) => {
    const bom = product.bom.map((child) => ({
      ...child,
      maxPackages: computeMaxPackage(child),
    }));

    const minPackagesNumber = Math.min(
      ...bom.map((child) => child.maxPackages),
    );

    return { ...product, maxInventory: minPackagesNumber };
  });

  console.log("rows", rows);

  return (
    <Page title="Products">
      <div className="card m-6 p-4 text-sm">
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th className="text-right">Inventory</th>
              <th className="text-right">Price</th>
              <th className="text-right">Active</th>
              <th className="text-right">In Stock</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((product) => (
              <tr
                key={product.id}
                className="border-y border-gray-100 dark:bg-gray-700"
              >
                <td>
                  <div className="flex space-x-2 items-center">
                    {product.image ? (
                      <img
                        src={product.image + "?imageView2/2/w/120"}
                        alt={product.name}
                        className="w-12 h-12 rounded-full"
                      />
                    ) : (
                      <div className="w-12 h-12 rounded-xl bg-gray-200"></div>
                    )}
                    <div>
                      <div>
                        <Link to={`${product.id}`}>{product.name}</Link>
                        <div className="space-x-3 text-xs">
                          {product.bom.map((child) => (
                            <span key={child.id}>
                              {child.wisProduct.number} x {child.qty}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="text-right">{product.maxInventory}</td>
                <td className="text-right">€ {product.price.toFixed(2)}</td>
                <td className="text-right">
                  <div className="flex justify-end">
                    {product.isActive ? (
                      <IoIosCheckmarkCircle className="text-green-500 text-xl" />
                    ) : (
                      <IoIosRemoveCircle className="text-red-500 text-xl" />
                    )}
                  </div>
                </td>
                <td className="text-right">
                  <div className="flex justify-end">
                    {product.inStock ? (
                      <IoIosCheckmarkCircle className="text-green-500 text-xl" />
                    ) : (
                      <div className="flex place-items-center space-x-2">
                        {product.backToStockAt ? (
                          <div className="text-red-500">
                            {" "}
                            {moment(product.backToStockAt).format(
                              "YYYY-MM-DD",
                            )}{" "}
                          </div>
                        ) : null}
                        <IoIosRemoveCircle className="text-red-500 text-xl" />
                      </div>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </Page>
  );
};

export default ProductList;
