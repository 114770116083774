import { computeMaxPackage } from "./inventory";

const InventoryView = ({ data }) => {
  const res = data.retailerStoreProduct.bom.map((child) => ({
    ...child,
    maxPackages: computeMaxPackage(child),
  }));

  return (
    <div className="card">
      <h5>Inventory</h5>

      <div className="-mx-2 mt-2">
        <table>
          <thead>
            <tr>
              <th>Product</th>
              <th className="text-right">Latest QTY</th>
              <th className="text-right">Unit QTY</th>
              <th className="text-right">Max Unit</th>
            </tr>
          </thead>
          <tbody>
            {res.map((child, index) => (
              <tr key={index} className="border-y">
                <td>{child.wisProduct.number}</td>
                <td className="text-right">
                  {child.wisProduct.warehouseInventory}
                </td>
                <td className="text-right">{child.qty}</td>
                <td className="text-right">{child.maxPackages}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default InventoryView;
