import { useMutation, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import { useParams } from "react-router-dom";
import { FETCH_RETAILER_ORDER, RETAILER_STORE_CANCEL_ORDER } from "./graphql";
import CharlesButton from "components/charles/base";
import { useModals } from "ModalProvider";
import ConfirmOfflinePaymentView from "./ConfirmOfflinePaymentView";
import { RetailerOrderStatusChain } from "components/Status";
import { useContext } from "react";
import { OdooContext } from "OdooProvider";
import { RiExternalLinkFill } from "react-icons/ri";
import StartShippingView from "./StartShippingView";
import { Alert } from "components/Toast";
import { parseError } from "apollo";

const OrderDetailPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_RETAILER_ORDER, {
    variables: { id },
  });
  const modal = useModals();
  const { odooUrl } = useContext(OdooContext);
  const [cancelOrder, cancelOrderRes] = useMutation(
    RETAILER_STORE_CANCEL_ORDER,
    {
      variables: { orderId: id },
      onCompleted() {
        Alert("success", "Order cancelled successfully");
      },
      onError(error) {
        Alert("error", parseError(error));
      },
    },
  );

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  const order = data.retailerOrder;

  function confirmPaymentHandler() {
    modal.present({
      title: "Confirm Payment",
      center: true,
      isBeingPresented: true,
      children: <ConfirmOfflinePaymentView hide={modal.hide} orderId={id} />,
    });
  }

  function startShipping() {
    modal.present({
      title: "Start Shipping",
      center: true,
      isBeingPresented: true,
      children: <StartShippingView hide={modal.hide} orderId={id} />,
    });
  }

  function cancelHandler() {
    if (
      window.confirm(
        `Are you sure to cancel this order? The order in Odoo would be cancel as well.`,
      )
    ) {
      cancelOrder();
    }
  }

  return (
    <Page title={order.id} backTo="./..">
      <div className="p-6 grid grid-cols-12 gap-6 text-sm">
        <div className="col-span-12">
          <RetailerOrderStatusChain status={order.state} />
        </div>

        <div className="col-span-8 space-y-8">
          <div className="card px-8 py-6">
            <h4>Order Items</h4>

            <div className="-mx-2 mt-4">
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th className="text-right">Quantity</th>
                    <th className="text-right">Price / Unit</th>
                    <th className="text-right">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {order.items.map((item) => (
                    <tr
                      key={item.id}
                      className=" border-y border-gray-100 dark:border-gray-700"
                    >
                      <td>
                        <div className="flex items-center space-x-2">
                          <div>
                            <img
                              src={item.product.image + "?imageView/2/w/300"}
                              className="w-12 h-12 rounded"
                            />
                          </div>
                          <div>
                            <div className="font-semibold">
                              {item.product.name}
                            </div>
                            <div className="text-xs opacity-70">
                              {item.product.bom.map((child, index) => (
                                <div key={index}>{child.wisProduct.number}</div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="text-right">{item.qty}</td>
                      <td className="text-right">
                        {item.unitPrice.toFixed(2)}
                      </td>
                      <td className="text-right">{item.subtotal.toFixed(2)}</td>
                    </tr>
                  ))}

                  {order.shippingCost > 0 && (
                    <tr className="border-y border-gray-100 dark:border-gray-700">
                      <td className="font-semibold">Shipping Cost</td>
                      <td></td>
                      <td></td>
                      <td className="text-right">
                        {order.shippingCost.toFixed(2)}
                      </td>
                    </tr>
                  )}
                </tbody>
                <tfoot>
                  <tr className=" font-semibold text-base">
                    <td className="">Total</td>
                    <td className="text-right">
                      {order.items.reduce((acc, item) => acc + item.qty, 0)}
                    </td>
                    <td></td>
                    <td className="text-right">{order.total.toFixed(2)}</td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <hr />

            <div className="-mx-2">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h5>Invoice Address</h5>
                      <div className="mt-2">{order.billingAddressName}</div>cl
                      <div>{order.billingAddress}</div>
                    </td>

                    <td>
                      <h5>Shipping Address</h5>
                      <div className="mt-2">{order.shippingAddressName}</div>
                      <div>{order.shippingAddress}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <hr />

            <div>
              <h5>Ship Date</h5>
              <div className="mt-2">{order.requestShipDate}</div>
            </div>

            <hr />

            <div>
              <h5>Remark</h5>
              <div className="mt-2">{order.remark}</div>
            </div>
          </div>
        </div>

        <div className=" col-span-4 space-y-8">
          <div className="card">
            <h4>Actions</h4>
            <div className="mt-2">
              {order.state === "AWAITING_PAYMENT" && (
                <div className=" space-y-2">
                  <p>
                    This order is awaiting payment. You will receive another
                    email notification once the order is paid or request to
                    validate a bank transfer.
                  </p>
                  <p>Inventory will be reserved for this order for 14 days.</p>
                </div>
              )}
              {order.state === "VALIDATING_PAYMENT" && (
                <div className="mt-4">
                  <div>
                    This order is waiting for payment confirmation. If this is
                    an offline payment, please confirm the payment manually.
                    Once you have received the payment, please confirm the
                    payment below.
                  </div>
                  <CharlesButton
                    className="mt-4"
                    onClick={confirmPaymentHandler}
                    primary
                  >
                    Confirm Payment
                  </CharlesButton>
                </div>
              )}

              {order.state === "PAID" && (
                <div className="mt-4">
                  <div>This order has been paid.</div>
                  <CharlesButton
                    className="mt-4"
                    onClick={startShipping}
                    primary
                  >
                    Start Shipping
                  </CharlesButton>
                </div>
              )}

              {order.state === "SHIPPING" && (
                <div className="mt-4">
                  <div>This order is being shipped.</div>
                </div>
              )}

              {order.state === "CANCELED" && (
                <div>The order has been cancelled.</div>
              )}
            </div>
          </div>

          <div className="card">
            <h4>Odoo</h4>
            <div className="mt-2">
              WIS auto create an order in Odoo for this order once it is
              created. WIS also cancel the order in Odoo once this order is
              cancelled. Please handle the order in Odoo accordingly.
            </div>
            {order.odooId && (
              <div className="mt-4">
                <a
                  target="_blank"
                  className="text-purple-600 flex items-center space-x-1"
                  rel="noreferrer"
                  href={`${odooUrl}/web#id=${order.odooId}&cids=1&menu_id=408&action=312&model=sale.order&view_type=form`}
                >
                  <span>{order.odooName}</span>
                  <span>
                    <RiExternalLinkFill />
                  </span>
                </a>
              </div>
            )}
          </div>

          {order.state === "AWAITING_PAYMENT" && (
            <div className="mx-1 flex justify-end opacity-70">
              <CharlesButton
                danger
                onClick={cancelHandler}
                loading={cancelOrderRes.loading}
              >
                Cancel Order
              </CharlesButton>
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default OrderDetailPage;
