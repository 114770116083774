import { gql, useQuery } from "@apollo/client";
import Errors from "components/Errors";
import Page from "components/Page";
import Spinner from "components/Spinner";
import CharlesButton from "components/charles/base";
import { useParams } from "react-router-dom";

const FETCH_RETAILER_DETAIL = gql`
  query FETCH_RETAILER_DETAIL($id: ID!) {
    retailerCustomer(id: $id) {
      id
      email
    }
  }
`;

const RetailerDetailPage = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(FETCH_RETAILER_DETAIL, {
    variables: { id },
  });

  if (loading) return <Spinner />;
  if (error) return <Errors error={error} />;

  return (
    <Page title={`Retailer: ${data.retailerCustomer.email}`} backTo="./..">
      <div className="m-6 grid grid-cols-12 gap-6">
        <div className=" col-span-8 space-y-6">
          <div className="card">
            <h5>Basic Info</h5>
            <div className="mt-4">...</div>
          </div>

          <div className="card">
            <h5>Recent Orders</h5>
            <div className="mt-4">...</div>
          </div>
        </div>
        <div className=" col-span-4 space-y-6 flex flex-col">
          <div className="card">
            <h4>Total Sales</h4>
            <div className="mt-4">...</div>
          </div>

          <div className="mx-1 flex justify-end">
            <CharlesButton danger>Delete Agent</CharlesButton>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default RetailerDetailPage;
