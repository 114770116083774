import { gql } from "@apollo/client";

export const FETCH_RETAILER_ORDERS = gql`
  query FETCH_RETAILER_ORDERS {
    retailerOrders {
      id
      user {
        id
        email
        retailer {
          id
          name
        }
      }
      odooId
      odooName
      total
      createdAt
      state
      remindPaymentAt
    }
  }
`;

export const ORDER_FRAGMENT = gql`
  fragment orderFragment on RetailerOrderType {
    id
    state
    odooId
    odooName
    items {
      id
      product {
        id
        name
        image
        bom {
          id
          qty
          wisProduct {
            id
            name
            number
          }
        }
      }
      unitPrice
      subtotal
      qty
    }
    latestPayment {
      id
      state
    }
    billingAddressName
    billingAddress
    shippingAddressName
    shippingAddress
    requestShipDate
    remark
    shippingCost
    total
  }
`;

export const FETCH_RETAILER_ORDER = gql`
  query FETCH_RETAILER_ORDER($id: ID!) {
    retailerOrder(id: $id) {
      ...orderFragment
    }
  }
  ${ORDER_FRAGMENT}
`;

export const CONFIRM_OFFLINE_PAYMENT = gql`
  mutation CONFIRM_OFFLINE_PAYMENT($orderId: ID!) {
    retailerConfirmOfflinePayment(orderId: $orderId) {
      order {
        ...orderFragment
      }
    }
  }
  ${ORDER_FRAGMENT}
`;

export const RETAILER_STORE_START_SHIPPING = gql`
  mutation RETAILER_STORE_START_SHIPPING($orderId: ID!) {
    retailerStartShipping(orderId: $orderId) {
      order {
        id
        state
      }
    }
  }
`;

export const RETAILER_STORE_CANCEL_ORDER = gql`
  mutation RETAILER_STORE_CANCEL_ORDER($orderId: ID!) {
    retailerCancelOrder(orderId: $orderId) {
      order {
        id
        state
      }
    }
  }
`;
